import logo from './logo.svg';
import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";                                //icons
import "/node_modules/primeflex/primeflex.css"
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom'
import QrForm from './components/QrForm/QrForm';
import Home from './Layout/Home/Home';
import Runsheet from './Layout/Runsheet/Runsheet';
import Mailout from './components/Mailout/Mailout';
import Signin from './Layout/Signin/Signin';
function App() {

  
  return (
    <div className="App">
      <Router>

        <Routes>
          <Route path='/' element={<Signin /> } />
          <Route path='/start' element={<Home />} />
          <Route path='/runsheet/' element={<Runsheet />} />
          <Route path='/walkin' element={<QrForm />} />
          <Route path='/mailout' element={<Mailout />} />
          {/* <Route path='/import' element={<Import />} /> */}
        </Routes>
      </Router>

    </div>
  );
}

export default App;
