import React, { useState } from 'react'
import { Calendar } from 'primereact/calendar'
import { InputTextarea } from 'primereact/inputtextarea'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import axios from 'axios'

export default function Mailout() {

    const [emailBody, setEmailBody] = useState("")
    const [eventDate, setEventDate] = useState(null)
    const [loading, setLoading] = useState(false)
    const [emailResponse, setEmailResponse] = useState("")

    const handleSubmit = () => {
        setLoading(true)
        axios.post('https://api.iphoto.nz/mailout', {
            "dateString": eventDate,
            "email_body": emailBody,
        }).then((response) => {
            console.log(response.data)
            setEmailResponse(response.data.message)
            setLoading(false)
        }).catch(() => {
            alert('Error, check form')
            setLoading(false)
        })
    }

    return (
        <div>
            <div className="form-wrapper mt-4 flex flex-column mx-4">
                {/* <Calendar id="touchUI" value={eventDate} dateFormat="yy-mm-dd" onChange={(e) => setEventDate(e.value)} touchUI /> */}
                <InputText className="mb-4" value={eventDate} onChange={(e) => setEventDate(e.target.value)} placeholder="2022-12-24" />
                <InputTextarea value={emailBody} onChange={(e) => setEmailBody(e.target.value)} rows={5} cols={30} autoResize />
                <Button label='submit' disabled={loading} onClick={handleSubmit} />
            <div className='text-left pt-4'>{emailResponse}</div>
            </div>
        </div>
  )
}
