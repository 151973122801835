import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import axios from 'axios'
export default function QrForm() {

    const [emailValue, setEmailValue] = useState("")
    const [firstNameValue, setFirstNameValue] = useState("")
    const [lastNameValue, setLastNameValue] = useState("")
    const [slugValue, setSlugValue] = useState("evt")

    const [qr, setQr] = useState(null)
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        setLoading(true)
        axios.post('https://api.iphoto.nz/customer', {
            "firstName": firstNameValue,
            "lastName": lastNameValue,
            "email": emailValue,
            "slug": slugValue,
            "bookingId": null,
            "customerId": null,
            "nextAppt": null,
        }).then((response) => {
            console.log(response)
            setQr(response)
            setLoading(false)
        }).catch((e) => {
            console.log(e)
            alert('Error, check form')
            setLoading(false)
        })
    }

    if (qr) {
        return <div style={{
            height: '80vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }} dangerouslySetInnerHTML={{ __html: qr.data }} />
    }
    return (

        <div className="form-wrapper mt-4 flex flex-column mx-4">
            <InputText className="mb-4" value={firstNameValue} onChange={(e) => setFirstNameValue(e.target.value)} placeholder="First Name" autoCapitalize='on' />
            <InputText className="mb-4" value={lastNameValue} onChange={(e) => setLastNameValue(e.target.value)} placeholder="Last Name" autoCapitalize='on' />
            <InputText className="mb-4" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder="email@example.com" autoComplete='off' />
            <input type="hidden" value={slugValue} />
            <Button label='submit' disabled={loading} onClick={handleSubmit} />
        </div>
    )
}
