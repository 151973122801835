import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { format, parseISO, isValid, getDate } from 'date-fns'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
// import { useParams } from 'react-router-dom'
export default function Runsheet() {
    const [runsheetData, setRunsheetData] = useState([])
    const [loading, setLoading] = useState(false)
    const [slug, setSlug] = useState("")
    const [dateString, setDateString] = useState(format(new Date(),'yyyy-MM-dd'))
    // const { dateString } = useParams()

    useEffect(() => {
        setSlug(window.localStorage.getItem('timeree_slug'))
    },[])

    useEffect(() => {
        setRunsheetData([])
        if (slug != "") {

            axios.get(`https://api.iphoto.nz/runsheet/${slug}/${dateString}`).then((response) => {
                setRunsheetData(response.data)
                setLoading(false)
            }).catch((e) => {
                setRunsheetData([])
                setLoading(false)
            })
        } 
    }, [slug,dateString])

    const handleDateChange = (val) => {
        if(val.split('-').length != 3) {
            return
        }
        if(isValid(parseISO(val, 'yyyy-MM-dd'))) {
            setDateString(val)
            
        }
            
        
    }

    if (!runsheetData) {
        return "Not found"
    }
    const RUNSHEET = runsheetData.map((customer, i) => {
        if (i % 3) {

        }
        return (
            <div style={{ pageBreakAfter: (i + 1) % 3 == 0 && 'always' }} className=' border-1 border-dashed'>

                <div style={{ height: 350 }} className="listItem grid flex justify-content-center align-items-center">
                    <div className='col-12'>{format(parseISO(dateString, 'yyyy-MM-DD'), 'eee dd MMM yy')}</div>
                    <div className='col-6  flex flex-column align-items-center justify-content-center'>

                        <div className=''>{customer.firstName} {customer.lastName}</div>
                        <div className=''>{format(parseISO(customer.nextAppt, 'yyyy-MM-DDThh:mm:ss'), 'hh:mma')}</div>
                    </div>
                    <div className=' col-6'>
                        <div className='qr-wrapper '>
                            <img width="150px" src={`data:image/jpeg;base64,${customer.qrBase64}`} />
                        </div>
                    </div>

                    <div className=' col surface-00'>{customer.email}</div>

                </div>
            </div>
        )
    })
    return (
        <>
            {/* <h2>{runsheetData.length} groups</h2> */}
            <div style={{pageBreakAfter: 'always', height: 200}} className="summary flex flex-column align-items-center justify-content-around ">
                <div><span className='font-bold'>{format(parseISO(dateString, 'yyyy-MM-dd'),'eeee dd MMMM yyyy')}</span></div>
                <div><span>{runsheetData.length} punters</span></div>
                <InputText style={{width: 200}} onChange={(e) => handleDateChange(e.target.value)} placeholder={dateString} />            </div>
            <div>{RUNSHEET}</div>
        </>
    )
}
