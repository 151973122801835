import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { Button } from 'primereact/button'
import axios from 'axios'
export default function Signin() {

    const [emailValue, setEmailValue] = useState("")
    const [pinValue, setPinValue] = useState("")
    const [preferences, setPreferences] = useState({})
    const [slugValue, setSlugValue] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleSubmit = () => {
        setLoading(true)
        axios.post('https://api.iphoto.nz/preferences', {
            "username": emailValue,
            "pin": pinValue,
        }).then((response) => {
            console.log(response)
            setPreferences(response.data)
            setTimeout(() => {
                navigate('/start')
            }, 1000)
        }).catch((e) => {
            if(e.response) {
                if (e.response.status == 401) {
                    alert('Wrong username or pin')
                } else {
                    alert(e.response.data)
                }
            }
            else if (e.request) {
                alert('Network error, try again later')
            }
            console.log(e)
            setLoading(false)
        })
    }

    useEffect(() => {
        console.log(preferences)
        window.localStorage.setItem('timeree_user', emailValue)
        window.localStorage.setItem('timeree_pin', pinValue)
        window.localStorage.setItem('timeree_slug', preferences.slug)
    },[preferences])
    
    return (

        <div className="form-wrapper mt-4 flex flex-column mx-4">
            <InputText className="mb-4" value={emailValue} onChange={(e) => setEmailValue(e.target.value)} placeholder="email@example.com" autoCapitalize='off' autoCorrect='off' />
            <Password className="mb-4" value={pinValue} onChange={(e) => setPinValue(e.target.value)} placeholder="####" feedback={false} />
            <Button label='submit' disabled={loading} onClick={handleSubmit} />
        </div>
    )
}
