import React from 'react'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
export default function Home() {
    const navigate = useNavigate()

    const handleButton = (n) => {
        navigate(n)
    }
    return (
        <div className='grid'>
            <div className='col-12'>

                <Button color='btn-primary' label="runsheet" onClick={e => {handleButton(`/runsheet/`)}} />
            </div>
            <div className='col-12'>

                <Button label="walk in" onClick={e => {handleButton('/walkin')}}/>
            </div>
        </div>
    )
}
